var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "title": "编辑资料",
      "width": "440px",
      "close-on-click-modal": false
    },
    on: {
      "open": _vm.openDialog,
      "close": _vm.cancel
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "70px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "title",
      "label": "标题：",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "我是文档或图片标题"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "url",
      "label": "附件：",
      "rules": [_vm.rules.required()]
    }
  }, [_c('f-upload', {
    ref: "uploader",
    attrs: {
      "type": 6,
      "limitSize": 5,
      "isCompress": false,
      "accept": ".pdf, .doc, .docx, .xls, .xlsx,.jpg,.jpeg,.png,.gif"
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.uploadSuccess(fileList);
      }
    }
  }), _c('file-item', {
    staticClass: "mt20",
    attrs: {
      "options": _vm.file
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提 交")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }