<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    title="编辑资料"
    width="440px"
    :close-on-click-modal="false"
    @open="openDialog"
    @close="cancel"
  >
    <el-form :model="formData" ref="form" label-width="70px">
      <el-form-item prop="title" label="标题：" :rules="[rules.required()]">
        <el-input
          v-model="formData.title"
          placeholder="我是文档或图片标题"
        ></el-input>
      </el-form-item>
      <el-form-item prop="url" label="附件：" :rules="[rules.required()]">
        <f-upload
          ref="uploader"
          :type="6"
          :limitSize="5"
          :isCompress="false"
          accept=".pdf, .doc, .docx, .xls, .xlsx,.jpg,.jpeg,.png,.gif"
          @on-success="fileList => uploadSuccess(fileList)"
        ></f-upload>
        <file-item :options="file" class="mt20"></file-item>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">提 交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import rules from "@/utils/rules";
import { docSize, docType } from "../_js/index";
import FUpload from "@/components/upload";
import { editProjectData } from "@/api/distribution";
import fileItem from "./fileItem";

export default {
  components: { FUpload, fileItem },
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rules,
      title: "添加资料",
      formData: {},
      file: {} // 上传的文件信息
    };
  },
  methods: {
    docSize,
    docType,
    openDialog() {
      if (this.options.id) {
        // 初始化项目资料
        this.file = { ...this.options };
        this.formData = { ...this.options };
      }
    },
    async uploadSuccess(fileList) {
      // 上传成功
      let arr = fileList.map(item => {
        let { title, docType } = this.docType(item.name);
        let { size, unit } = this.docSize(item.size);
        return {
          estateId: this.estateId,
          type: docType == "image" ? 1 : 2, // 1: 图片, 2: 文档
          url: item.url,
          title,
          docType,
          size,
          unit
        };
      });
      this.file = { ...arr[0] };
      this.formData = {
        ...this.file,
        id: this.formData.id,
        estateId: this.formData.estateId
      };
    },
    cancel() {
      this.$emit("update:visible", false);
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await editProjectData(this.formData);
        if (res) {
          this.$showSuccess("编辑成功");
          this.cancel();
          this.$emit("refresh");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
