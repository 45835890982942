<template>
  <div class="base-container">
    <!--header-mol-->
    <div class="header-mol">
      <div class="info">
        <div class="title">资料库</div>
        <div class="summary">
          面向分销渠道的项目推广资料，文档（支持word、Excel、PDF）、图片（支持JPG、png、gif）
        </div>
      </div>
      <div class="action">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAddDialog = true"
          >添加资料</el-button
        >
      </div>
    </div>
    <!--header-mol end-->
    <el-table border class="mt20" :data="list">
      <el-table-column label="资料" width="400">
        <template v-slot="{ row }">
          <file-item :options="row" :sourceTable="true"></file-item>
        </template>
      </el-table-column>
      <el-table-column label="类型">
        <template v-slot="{ row }">
          {{ row.type == 1 ? "图片" : "文档" }}
        </template>
      </el-table-column>
      <el-table-column prop="updateUserName" label="更新人">
        <template v-slot="{ row }">
          {{ row.updateUserName || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-button type="text" @click="editData(row)">编辑</el-button>
          <el-button type="text" @click="del(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageNumChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="total"
    >
    </el-pagination>
    <add-project
      :visible.sync="showAddDialog"
      v-if="showAddDialog"
      @refresh="refresh"
    ></add-project>
    <edit-project
      :visible.sync="showEditDialog"
      :options="curRow"
      @refresh="getList"
    ></edit-project>
  </div>
</template>

<script>
import addProject from "./_components/add";
import editProject from "./_components/edit";
import fileItem from "./_components/fileItem";
import { projectList, deleteProjectData } from "@/api/distribution";
export default {
  components: { addProject, editProject, fileItem },
  data() {
    return {
      showAddDialog: false,
      showEditDialog: false,
      list: [],
      total: 0,
      curRow: {},
      pageSizes: [10, 20, 30, 50],
      queryData: {
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  created() {
    this.getList();
  },
  methods: {
    refresh() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    async getList() {
      const res = await projectList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    async del(id) {
      const res = await deleteProjectData({ id });
      if (res) {
        this.refresh();
      }
    },
    editData(row) {
      // 编辑资料
      this.showEditDialog = true;
      this.curRow = row;
    },
    //页面条数
    pageSizeChange(val) {
      this.queryData.pageSize = val;
      this.getList();
    },
    //当前页面
    pageNumChange(val) {
      this.queryData.pageNum = val;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.el-table {
  td {
    vertical-align: top;
  }
  ::v-deep {
    .el-button {
      padding: 0;
    }
    tr:hover {
      .item-file {
        .title {
          color: $primary;
        }
      }
    }
  }
}
</style>
