var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "title": _vm.title,
      "width": "400px",
      "close-on-click-modal": false
    },
    on: {
      "close": _vm.cancel
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "data",
      "label": "",
      "rules": [_vm.rules.required()]
    }
  }, [_c('f-upload', {
    ref: "uploader",
    attrs: {
      "type": 5,
      "limitSize": 50,
      "isCompress": false,
      "accept": ".pdf, .doc, .docx, .xls, .xlsx,.jpg,.jpeg,.png,.gif,.PDF,.DOC,.DOCX,.XLS,.XLSX,.JPG,.JPEG,.PNG,.GIF",
      "default-file-list": _vm.fileList,
      "showProcess": true
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.uploadSuccess(fileList);
      }
    }
  }), _vm._l(_vm.formData.data, function (file) {
    return _c('div', {
      key: file.id,
      staticClass: "mt15"
    }, [_c('file-item', {
      attrs: {
        "options": file,
        "isEdit": file.isEdit
      }
    }, [file.isEdit ? _c('edit-file', {
      attrs: {
        "title": _vm.curItem.title
      },
      on: {
        "update:title": function updateTitle($event) {
          return _vm.$set(_vm.curItem, "title", $event);
        },
        "close": function close($event) {
          _vm.curItem.isEdit = false;
        }
      }
    }) : [_c('div', {
      staticClass: "percent"
    }, [file.percent < 100 ? _c('span', [_vm._v(_vm._s(file.percent) + "%")]) : _c('i', {
      staticClass: "el-icon-circle-check success"
    })]), _c('div', {
      staticClass: "action"
    }, [_c('i', {
      staticClass: "el-icon-edit-outline",
      on: {
        "click": function click($event) {
          return _vm.editItem(file);
        }
      }
    }), _c('i', {
      staticClass: "el-icon-delete",
      on: {
        "click": function click($event) {
          return _vm.removeItem(file);
        }
      }
    })])]], 2), _c('div', {
      staticClass: "progress"
    }, [file.percent < 100 ? _c('el-progress', {
      attrs: {
        "percentage": file.percent,
        "show-text": false,
        "stroke-width": 4
      }
    }) : _vm._e()], 1)], 1);
  })], 2)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提 交")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }