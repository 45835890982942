import request from "@/utils/request";
import { showLoading } from "../utils";

// 查询楼盘渠道分销状态
export function getEstateCommonRule(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/getEstateCommonRule`,
    method: "post",
    data
  });
}

// 新增、编辑导客规则
export function editCommonRule(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/editCommonRule`,
    method: "post",
    data
  });
}

// 查询楼盘渠道分销状态
export function getEnterpriseChannelStatus(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/getEnterpriseChannelStatus`,
    method: "post",
    data
  });
}

// 修改楼盘渠道分销状态
export function changeEnterpriseChannelStatus(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/changeEnterpriseChannelStatus`,
    method: "post",
    data
  });
}

// 查询自定义渠道列表
export function getDefineChannelList(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/getDefineChannelList`,
    method: "post",
    data
  });
}

// 批量创建自定义渠道
export function createDefineChannelBatch(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/createDefineChannelBatch`,
    method: "post",
    data
  });
}

// 删除自定义渠道
export function deleteDefineChannel(id) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/deleteDefineChannel`,
    method: "post",
    data: { id }
  });
}

// 开关自定义渠道
export function openCloseDefineChannel(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/openCloseDefineChannel`,
    method: "post",
    data
  });
}

// 编辑自定义渠道
export function editDefineChannel(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/editDefineChannel`,
    method: "post",
    data
  });
}

// 查询佣金方案列表
export function getEstateCommissionList(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/getEstateCommissionList`,
    method: "post",
    data
  });
}

// 编辑佣金方案
export function updateCommission(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/updateCommission`,
    method: "post",
    data
  });
}

// 添加佣金方案
export function createCommission(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/createCommission`,
    method: "post",
    data
  });
}

// 删除佣金方案
export function deleteCommission(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/deleteCommission`,
    method: "post",
    data
  });
}

// 查询带看奖励
export function getEstateApplyAward(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/getEstateApplyAward`,
    method: "post",
    data
  });
}

// 创建带看奖励
export function createEstateAward(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/createEstateAward`,
    method: "post",
    data
  });
}

// 编辑带看奖励
export function editEstateAward(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/editEstateAward`,
    method: "post",
    data
  });
}

// 开关带看奖励
export function openCloseEstateAward(data) {
  return request({
    url: `/api/v1/fyb/web/channel/manage/openCloseEstateAward`,
    method: "post",
    data
  });
}

// 修改楼盘合作期限
export const changeEnterpriseCooperationDate = data =>
  request({
    url: `/api/v1/fyb/web/channel/manage/changeEnterpriseCooperationDate`,
    data
  });

// 项目资料列表
export const projectList = data =>
  request({
    url: `/api/v1/platform/distribution/web/project/list`,
    method: "post",
    data
  });

// 添加项目资料
export const createProjectData = data =>
  request({
    url: `/api/v1/platform/distribution/web/project/create`,
    method: "post",
    data
  });

// 编辑项目资料
export const editProjectData = data =>
  request({
    url: `/api/v1/platform/distribution/web/project/edit`,
    method: "post",
    data
  });

// 删除项目资料
export const deleteProjectData = data =>
  request({
    url: `/api/v1/platform/distribution/web/project/delete?id=${data.id}`,
    method: "post"
  });

// 规则详情
export const rulesDetail = data =>
  request({
    url: `/api/v1/platform/distribution/web/rules/detail`,
    method: "get",
    data
  });

// 编辑规则
export const editRules = data =>
  request({
    url: `/api/v1/platform/distribution/web/rules/edit`,
    method: "post",
    data
  });

// 报备列表
export const getReportList = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/list`,
    data
  });

// 报备列表
export const getReportListV2 = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/list/v2`,
    data
  });

// 发现该客户的历史报备
export const getReportSameListV2 = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/customerRecord/v2/${data.reportId}`,
    data,
    showLoading: false
  });

// 报备列表条件 conditionType 1 项目 2 渠道 3 报备人员 4 置业顾问
export const getReportListConditionV2 = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/list/v2/condition`,
    method: "GET",
    data,
    showLoading: false
  });

// 报备详情
export const getReportDetail = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/info/${data.id}`,
    data
  });

// 报备列表条件(机构、项目、来源)
export const getReportListCondition = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/listCondition`,
    data
  });

// 报备列表条件(机构、项目、来源)
export const getReportHistoryCountByCustomer = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/customerRecordCount/${data.reportId}`,
    data
  });

// 报备列表条件(机构、项目、来源)
export const getReportHistoryByCustomer = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/customerRecord/${data.reportId}`,
    data
  });

// 渠道报备排行
export const getReportRankListByChannel = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/list/top`,
    data
  });

// 客户判定统计
export const getCustomerDetermineStatistics = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/list/determine`,
    data
  });

// 渠道跟进统计-渠道
export const getMechanismFollowStatistics = data =>
  request({
    url: `/api/v1/platform/distribution/web/mechanism/log/counts/mechanism`,
    data
  });

// 渠道跟进统计-用户
export const getMechanismUserFollowStatistics = data =>
  request({
    url: `/api/v1/platform/distribution/web/mechanism/log/counts/user`,
    data
  });

// 报备带看 日历
export const reportCalendar = data =>
  request({
    url: `/api/v1/platform/distribution/web/report/list/calendar`,
    data
  });

// 更新报备时间
export const updateReportTime = params =>
  request({
    url: `/api/v1/platform/distribution/web/report/update/date`,
    params
  });
