export const docSize = limit => {
  // 文件大小，文件大小单位
  let size = "";
  let unit = "";
  if (limit < 0.1 * 1024 * 1024) {
    //如果小于0.1MB转化成KB
    size = (limit / 1024).toFixed(2);
    unit = "kb";
  } else {
    //如果大于0.1MB转化成MB
    size = (limit / (1024 * 1024)).toFixed(2);
    unit = "M";
  }

  var sizestr = size + "";
  var len = sizestr.indexOf(".");
  var dec = sizestr.substr(len + 1, 2);
  if (dec == "00") {
    //当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
  }
  return {
    size,
    unit
  };
};

export const docType = fileName => {
  // 文件名称，文件类型
  let title = fileName.substring(0, fileName.lastIndexOf("."));
  let extension = fileName
    .substring(fileName.lastIndexOf(".") + 1)
    .toLowerCase();

  let map = {
    image: "jpg,jpeg,png,gif",
    word: "doc,docx",
    excel: "xls,xlsx",
    pdf: "pdf"
  };

  let docType = Object.keys(map).find(type => {
    return map[type].split(",").includes(extension);
  });

  return {
    title,
    docType
  };
};
