<template>
  <el-form :model="formData" ref="form" :inline="true" class="demo-form-inline">
    <el-form-item prop="title" :rules="[rules.required()]">
      <el-input size="mini" v-model="formData.title"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button size="mini" @click="cancel">取消</el-button>
      <el-button type="primary" size="mini" @click="submit">确定</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import rules from "@/utils/rules";
export default {
  props: {
    title: {
      // 文件名称
      type: String,
      default: ""
    }
  },
  data() {
    return {
      rules,
      formData: {
        title: ""
      }
    };
  },
  created() {
    this.formData.title = this.title;
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.$emit("close");
        this.$emit("update:title", this.formData.title);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.el-input {
  width: 180px;
}

::v-deep .el-input__inner {
  padding: 0 8px;
}
.el-button--mini {
  padding: 7px;
}
</style>
