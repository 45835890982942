<template>
  <div class="item-file">
    <div class="img-box">
      <img src="@/assets/word.png" v-if="options.docType == 'word'" />
      <img src="@/assets/excel.png" v-if="options.docType == 'excel'" />
      <img src="@/assets/pdf.png" v-if="options.docType == 'pdf'" />
      <img :src="options.url" v-if="options.docType == 'image'" />
    </div>
    <div class="info" v-if="!isEdit">
      <div class="title" @click="openFile">{{ options.title }}</div>
      <p>
        大小 {{ options.size }}{{ options.unit }}
        <span v-if="sourceTable">查看 {{ options.browseCount }}</span>
      </p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import rules from "@/utils/rules";
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    sourceTable: {
      // 表格特有的(浏览量)
      type: Boolean,
      default: false
    },
    isEdit: {
      // 是否为编辑状态(编辑状态下，显示文件标题编辑框，隐藏文件标题和文件大小)
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    openFile() {
      if (!this.sourceTable) return;
      // 资料列表才需要跳转预览
      let url = ["image", "pdf"].includes(this.options.docType)
        ? this.options.url
        : `https://view.officeapps.live.com/op/view.aspx?src=${this.options.url}`;
      window.open(url);
    }
  }
};
</script>

<style scoped lang="scss">
.item-file {
  display: flex;
  align-items: center;
  .img-box {
    width: 48px;
    height: 48px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    img {
      max-width: 48px;
      height: 48px;
    }
  }
  .info {
    flex: 1;
    display: flex;
    height: 48px;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    font-size: 14px;
    color: #333;
    line-height: 160%;
    cursor: pointer;
  }
  p {
    font-size: 12px;
    color: #aaa;
    line-height: 100%;
  }
}
.el-input {
  width: 160px;
}

::v-deep .el-input__inner {
  padding: 0 8px;
}
.el-button--mini {
  padding: 7px;
}
</style>
