var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-container"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('div', {
    staticClass: "action"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        _vm.showAddDialog = true;
      }
    }
  }, [_vm._v("添加资料")])], 1)]), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "资料",
      "width": "400"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('file-item', {
          attrs: {
            "options": row,
            "sourceTable": true
          }
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.type == 1 ? "图片" : "文档") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "updateUserName",
      "label": "更新人"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.updateUserName || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "updateTime",
      "label": "更新时间"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.editData(row);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.del(row.id);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.queryData.pageNum,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.queryData.pageSize,
      "layout": "sizes, prev, pager, next,jumper",
      "total": _vm.total
    },
    on: {
      "size-change": _vm.pageSizeChange,
      "current-change": _vm.pageNumChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      }
    }
  }), _vm.showAddDialog ? _c('add-project', {
    attrs: {
      "visible": _vm.showAddDialog
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showAddDialog = $event;
      },
      "refresh": _vm.refresh
    }
  }) : _vm._e(), _c('edit-project', {
    attrs: {
      "visible": _vm.showEditDialog,
      "options": _vm.curRow
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showEditDialog = $event;
      },
      "refresh": _vm.getList
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("资料库")]), _c('div', {
    staticClass: "summary"
  }, [_vm._v(" 面向分销渠道的项目推广资料，文档（支持word、Excel、PDF）、图片（支持JPG、png、gif） ")])]);

}]

export { render, staticRenderFns }