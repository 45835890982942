var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    ref: "form",
    staticClass: "demo-form-inline",
    attrs: {
      "model": _vm.formData,
      "inline": true
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "title",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "size": "mini"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "size": "mini"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "mini"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }