var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item-file"
  }, [_c('div', {
    staticClass: "img-box"
  }, [_vm.options.docType == 'word' ? _c('img', {
    attrs: {
      "src": require("@/assets/word.png")
    }
  }) : _vm._e(), _vm.options.docType == 'excel' ? _c('img', {
    attrs: {
      "src": require("@/assets/excel.png")
    }
  }) : _vm._e(), _vm.options.docType == 'pdf' ? _c('img', {
    attrs: {
      "src": require("@/assets/pdf.png")
    }
  }) : _vm._e(), _vm.options.docType == 'image' ? _c('img', {
    attrs: {
      "src": _vm.options.url
    }
  }) : _vm._e()]), !_vm.isEdit ? _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": _vm.openFile
    }
  }, [_vm._v(_vm._s(_vm.options.title))]), _c('p', [_vm._v(" 大小 " + _vm._s(_vm.options.size) + _vm._s(_vm.options.unit) + " "), _vm.sourceTable ? _c('span', [_vm._v("查看 " + _vm._s(_vm.options.browseCount))]) : _vm._e()])]) : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }